// colors
$dark-color: rgba(22, 29, 55, 1); //#161D37
$dark-hover-color: rgba(22, 29, 55, 0.7);

$blue-color: rgba(104, 123, 254, 1); // #687BFE
$white-color: rgba(230, 239, 251); // #E6EFFB
$grey-color: rgba(239, 242, 248, 1); // #EFF2F8

$dashboard-bgcolor: rgba(245, 250, 255, 1); // #F5FAFF
$inputform-bgcolor: rgba(239, 242, 248, 1);

$hr-color: rgba(111, 114, 139, 0.23);
