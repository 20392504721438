/* Position and sizing of burger button */
.pro-sidebar .bm-burger-button {
  display: none;
  width: 26px;
  height: 24px;
}

.landing-header .bm-burger-button {
  display: none;
  width: 26px;
  height: 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  height: 9% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
  opacity: 0.8;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

.landing-header .bm-cross-button {
  height: 48px !important;
  width: 48px !important;
  top: 15px !important;
  right: 25px !important;
}

.landing-header .bm-cross {
  height: 30px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  display: none;
}

.burgermenulanding-container .bm-menu-wrap {
  width: 100vw !important;
  top: 0 !important;
}

/* General sidebar styles */
.bm-menu {
  background: $dark-color;
  padding: 2.5em 0 0;
  font-size: 1.15em;
}
.burger-cross {
  height: 4px !important;
  width: 4px !important;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 1em 0;
}

.landing-header .burger-menu-container {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* Individual item */
.bm-item {
  // display: inline-block;
}

/* Styling of overlay */
.pro-sidebar .bm-overlay {
  background: $dark-color;
  display: none;
}

.landing-header .bm-overlay {
  background: $dark-color;
  display: none;
}

#react-burger-cross-btn:hover {
  opacity: 0.7;
}

.landing-burger-title {
}

.landing-login-burger-btn {
  @include landing-btn-style;
  width: 120px;
  height: 42px;
  border: none;
  background: transparent;
  font-size: 20px;
}

.landing-login-burger-btn:hover {
  @include landing-btn-hover-style;
}

.landing-login-burger-btn:active {
  @include landing-btn-active-style;
}

.landing-join-burger-btn {
  @include landing-btn-style;
  width: 240px;
  height: 60px;
  border: 1px solid white;
  background: transparent;
  margin-bottom: 50px;
  font-size: 20px;
}

.landing-join-burger-btn:hover {
  @include landing-btn-hover-style;
}

.landing-join-burger-btn:active {
  @include landing-btn-active-style;
}

.burgermenu-menu-title {
  font-size: 16px;
  color: #ced3db;
  line-height: 55px;
}

.landing-burgermenu-container {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.landing-burgermenu-container .menu-item {
  margin-bottom: 60px;
  font-size: 22px;
  text-decoration: none;
  color: white;
  // opacity: 0.7;
}

.landing-burger-logo-txt {
  position: absolute;
  left: 40px;
  top: -30px;
  font-size: 20px;
  font-weight: 900;
  line-height: 130%;
  color: white;
}

.landing-burger-decor1 {
  position: absolute;
  left: -40px;
  top: -60px;
  display: none;
}

.burgermenu-hr {
  margin: 20px 20px;
  // position: fixed;
  // bottom: 130px;
  // height: 1px;
  // width: 84%;
  // display: none;
}

.landing-burgermenu-hr {
  // position: fixed;
  // bottom: 250px;
  height: 1px;
  width: 84%;
  display: none;
}

.landing-burgermenu-container a:hover {
  opacity: 1;
}

.landing-burgermenu-container a:focus {
  opacity: 1;
}

.burger-user-info-container {
  // @include flex-style(row, flex-start, center);
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #161d37;
}

.burger-user-info-group {
  display: inline-block;
}
.info-container {
  margin: 20px;
  display: flex;
}
.name-circle {
  background: #687bfe;
  font-size: 16px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  border-radius: 50%;
}
.burger-user-info-initial-letter {
  font-weight: bold;
  fill: white;
  font-size: 18px;
}

.burger-user-name {
  font-weight: normal;
  font-size: 18px;
  margin-left: 20px;
}

@media screen and (max-width: 1060px) {
  .landing-header .bm-burger-button {
    display: block;
    position: relative;
    margin-right: 25px;
    top: 0;
  }

  .landing-header .bm-overlay {
    display: block;
  }

  .landing-header .bm-menu-wrap {
    display: block;
  }

  .landing-burgermenu-hr {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .pro-sidebar .bm-burger-button {
    display: block;
    position: relative;
    margin: 0 24px;
    width: 18px;
    height: 16px;
    top: 0;
  }

  .pro-sidebar .bm-overlay {
    display: block;
  }

  .pro-sidebar .bm-menu-wrap {
    display: block;
    position: fixed;
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
  }

  .pro-sidebar .bm-cross-button {
    height: 40px !important;
    width: 40px !important;
    top: 15px !important;
    right: 25px !important;
  }

  .bm-cross {
    height: 15px !important;
  }

  .burger-header-container:focus-visible {
    outline: 0;
  }
  .burger-header {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .burger-header-logo {
    color: white;
    font-size: 22px;
  }

  .pro-sidebar .burger-menu-container {
    padding: 20px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 100px;
  }

  .pro-sidebar .menu-item {
    padding: 0px 38px;
    color: white;
    opacity: 0.7;
    font-size: 18px;
    line-height: 65px;
    background: no-repeat top left;
  }

  .pro-sidebar .menu-item:hover {
    opacity: 1;
  }

  .burger-propertylist-icon {
    padding-right: 18px;
  }

  .burger-setting-icon {
    padding-right: 18px;
  }

  .burger-company-icon {
    padding-right: 18px;
  }

  .burger-notification-icon {
    padding-right: 18px;
  }

  .burger-user-info-container {
    display: flex;
  }

  .burgermenu-hr {
    // display: block;
  }
}
