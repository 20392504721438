.addproperty-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 130%;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.addproperty-subtitle {
  padding-bottom: 2rem;
}
