// Dashboard page skeleton

.dashboard-noti-info-container {
  padding: 0;
}

.skeleton-background {
  // background: url('../../assets/images/tile-skeleton.svg') center / cover
  //   no-repeat !important;
}
.tile-skeleton-container {
  width: 229px;
  height: 305px;
  outline: none;
  border: none;
  background: url('../../assets/images/tile-skeleton.svg');
  border-radius: 4px;
  @include flex-style(column, flex-start, flex-start);
  position: relative;
}

.tile-highlight-skeleton-photo {
  width: 229px;
  height: 140px;
}

.tile-btn-container-skeleton {
  @include flex-style(row, space-between, center);
  width: 200px;
  margin-top: 10px;
  position: absolute;
  bottom: 15px;
  left: 10px;
}

.property-photo-skeleton-container {
  // position: relative;
  // top: -3px;
}

.property-photo-skeleton-desktop {
  display: block;
}

.property-photo-skeleton-mobile {
  display: none;
  // padding-top: 3px;
}

.noti-info-group-skeleton-container {
  margin: 0 0px;
  // background: url('../../assets/images/moneyspent-skeleton.svg');
  // background-size: cover;
  overflow-y: auto;
  // background-color: white;
}

.noti-skeleton-container {
  background: url('../../assets/images/moneyspent-skeleton.svg');
  background-size: cover;
  @include flex-style(row, space-between, center);
  padding: 0 20px;
  width: 100%;
}

.noti-thumb-img-skeleton-container {
  padding: 2px 0px 4px 0px;
  position: relative;
}

//Notification list page

.noti-skeleton-container .noti-container-left {
  width: 80%;
  @include flex-style(row, flex-start, center);
}

// Project page skeleton

.project-highlight-photo-skeleton {
  @include skeleton-color-style;
  border-radius: 8px;
  width: 145px;
  height: 145px;
  margin-right: 40px;
}

.project-property-name-skeleton {
  @include skeleton-color-style;
  width: 350px;
  height: 40px;
  margin: 8px 30px 15px 0px;
}

.budget-mark-img-skeleton {
  @include skeleton-color-style;
  width: 32px;
  height: 32px;
  // margin: 0 10px 0 20px;
  margin-right: 18.67px;
}

.barchartspent-skeleton-container {
  height: 74%;
  @include flex-style(row, center, center);
  margin: 0 12px;
  background: url('../../assets/images/moneyspentchart-skeleton.svg');
  background-size: cover;
}

.budget-chart-skeleton-container {
  padding: 26px 32px 32px 32px;
  width: 99.9%;
  height: 100%;
  background: url('../../assets/images/moneyspent-skeleton.svg');
  background-size: cover;
}

.budgetchart-body-skeleton {
  @include skeleton-color-style;
  width: 99.9%;
  height: 200px;
}

.metrics-chart-skeleton {
  padding: 26px 32px 32px 32px;
  height: 281px;
  background: url('../../assets/images/moneyspent-skeleton.svg');
  background-size: cover;
  @include flex-style(column, flex-start, stretch);
}

.linechartclicks-skeleton-container {
  height: 77%;
  width: 549px;
  @include flex-style(row, center, center);
  padding-right: 5px;
  background: url('../../assets/images/moneyspentchart-skeleton.svg');
  background-size: cover;
}

.value-budget-skeleton-container {
  padding: 23.5px 26.67px;
  width: 100%;
  background: url('../../assets/images/moneyspent-skeleton.svg');
  border-radius: 5px;
  background-size: cover;
  @include flex-style(row, flex-start, center);
}

.project-header-skeleton-container {
  background: url('../../assets/images/moneyspent-skeleton.svg');
  background-size: cover;
  position: relative;
  width: calc(100vw - 80px);
  left: -40px;
}

.metrics-chartbody-skeleton {
  // margin-top: 10px;
  // height: 74%;
  // @include flex-style(row, center, center);
  // margin: 0 12px ;
  // background: url('../../assets/images/moneyspentchart-skeleton.svg');
  // background-size: cover;
  @include skeleton-color-style;
  width: 99.9%;
  height: 185px;
  // margin-left: 12px;
}

// Settings page skeleton

.settings-skeleton-block1 {
  margin-top: 20px;
  // background: url('../../assets/images/moneyspent-skeleton.svg');
  // background-size: cover;
  background-color: white;
}

.settings-skeleton-block2 {
  margin-top: 20px;
  // background: url('../../assets/images/moneyspent-skeleton.svg');
  // background-size: cover;
  background-color: white;
}

@media screen and (max-width: 1600px) {
  .budget-chart-skeleton-container {
    // width: 84%;
  }
}

@media screen and (max-width: 1288px) {
}

@media screen and (max-width: 1140px) {
  .project-property-name-skeleton {
    width: 409px;
  }

  .value-budget-skeleton-container {
    width: 220px;
  }

  .budget-mark-img-skeleton {
    // margin: 0 20px 0 40px;
  }

  .budget-chart-skeleton-container {
    // width: 100%;
  }
}

@media screen and (max-width: 1080px) {
  .project-highlight-photo-skeleton {
    width: 190px;
    height: 190px;
  }
}

@media screen and (max-width: 995px) {
  .project-property-name-skeleton {
    width: calc(100vw - 390px);
  }
}

@media screen and (max-width: 840px) {
  .value-budget-skeleton-container {
    width: 180px;
  }

  .budget-mark-img-skeleton {
    // margin: 0 10px 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .project-highlight-photo-skeleton {
    width: calc(100vw - 170px);
    height: 300px;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .project-property-name-skeleton {
    width: calc(100vw - 180px);
    margin: 0 0px 15px 0px;
  }

  .value-budget-skeleton-container {
    flex-direction: column;
    padding: 19px 18px;
    width: 150px;
    margin-right: 12px;
    align-items: flex-start;
    // margin-right: 0px;
    margin-bottom: 20px;
    // width: 100%;
    // height: auto;
    // padding: 20px 0px;
    // padding-left: calc(50% - 70px);
  }

  .budget-mark-img-skeleton {
    // margin: 0 10px 0 20px;
  }
}

@media screen and (max-width: 660px) {
  .value-budget-skeleton-container {
    // @include flex-style(column, flex-start, flex-start);
    // width: 120px;
    // height: auto;
    // padding-left: 20px;
  }

  .budget-mark-img-skeleton {
    // margin: 20px 0 10px 0px;
  }
}

@media screen and (max-width: 576px) {
  .project-header-skeleton-container {
    left: -20px;
    width: 100vw;
  }
  .project-highlight-photo-skeleton {
    width: calc(100vw - 80px);
  }

  .project-property-name-skeleton {
    width: calc(100vw - 100px);
  }

  .tile-skeleton-container {
    width: 335px;
    height: 174px;
    flex-direction: row;
    padding: 12px;
    background: url(../../assets/images/tile-skeleton.svg);
    background-size: cover;
    // margin: auto;
  }

  .tile-highlight-skeleton-photo {
    width: 113px;
    height: 146px;
  }

  .tile-btn-container-skeleton {
    left: 130px;
    bottom: 12px;
  }

  .property-photo-skeleton-desktop {
    display: none;
  }

  .property-photo-skeleton-mobile {
    display: block;
    width: 113px;
    height: 146px;
  }
}

@media screen and (max-width: 448px) {
  .budget-mark-img-skeleton {
    // margin: 0 20px 0 0px;
  }
}
