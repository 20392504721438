.input-container {
  position: relative;
  @include flex-style(row, flex-start, center);
  width: 100%;
}

.img-decor {
  position: absolute;
  // top: 18px;
  left: 17px;
}

.inputform {
  border: 1.75px solid transparent;
  outline: none;
  background-color: #f2f5f9;
  line-height: 46px;
  border-radius: 4px;
  width: 100%;
}
.modal-input-container .inputform {
  line-height: 24px;
  padding: 8px 0 8px;
  font-size: 16px;
  font-weight: 400;
}
.modal-input-container .img-decor {
  // top: 29px;
}
.inputform:focus {
  border: 1.75px solid $blue-color;
  background-color: white;
}
.inputform::placeholder {
  color: #707787;
}
.inputform:focus::placeholder {
  color: transparent;
}
.eye-decor {
  position: absolute;
  right: 20px;
}

// .eye-container {
//   width: fit-content;
//   position: absolute;
//   // top: 14px;
//   right: 20px;
// }

@media screen and (max-width: 768px) {
  .inputform {
    line-height: 46.5px;
  }

  .img-decor {
    // top: 30px;
  }

  .eye-decor {
  }
}
@media screen and (max-width: 375px) {
  .eye-container {
    // margin-top:
    // top: 32px;
    // right: 10px;
  }

  .inputform {
    margin: 0;
  }

  .img-decor {
  }
}
