.profile-footer-btn-container {
  // @include flex-style(row, flex-end, center);
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0 16px;
  justify-content: end;
  margin-top: 60px;
}

.profile-footer-btn-container > .flair-btn {
  width: 220px;
}

@media screen and (max-width: 576px) {
  .profile-footer-btn-container {
    grid-template-columns: 100%;
    grid-gap: 16px 0;
    margin-top: 60px;
  }

  .profile-footer-btn-container > .flair-btn {
    width: 100%;
  }

  .profile-footer-btn-container > .flair-primary-btn {
    order: -1;
  }
}
.profile-footer-cancel-btn {
  @include cancel-btn-style;
  width: 220px;
  height: 52px;
  margin-right: 20px;
}

.profile-footer-cancel-btn:hover {
  @include cancel-btn-hover-style;
}

.profile-footer-cancel-btn:active {
  @include cancel-btn-active-style;
}

.profile-footer-save-btn {
  @include confirm-btn-style;
  width: 220px;
  height: 52px;
}

.profile-footer-save-btn:hover {
  @include confirm-btn-hover-style;
}

.profile-footer-save-btn:active {
  @include confirm-btn-active-style;
}

.profile-change-pwd {
  position: absolute;
  right: 14px;
  bottom: -30px;
  color: $dark-color;
  font-size: 14px;
  font-weight: 600;
  line-height: 15.4px;
}

.profile-change-pwd:hover {
  cursor: pointer;
  color: $blue-color;
  font-weight: bold;
  bottom: -29px;
}

.profile-change-pwd:active {
  bottom: -30px;
}

#profile-block3 {
  margin-bottom: 40px;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .profile-change-pwd {
    right: 22px;
    bottom: -30px;
    color: $dark-color;
  }
}

@media screen and (max-width: 375px) {
  .profile-footer-btn-container {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .profile-footer-cancel-btn {
    margin-right: 0px;
    margin-top: 20px;
    order: 2;
    width: auto;
  }

  .profile-footer-save-btn {
    order: 1;
    width: auto;
  }

  #profile-block1 {
    padding: 20px 0;
  }

  #profile-block2 {
    padding: 20px 0;
  }

  #profile-block3 {
    padding: 20px 0;
  }
}
