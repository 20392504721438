body,
html {
  margin: 0;
  color: #161d37;
  font-family: PublicSans !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:focus-visible {
  outline: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e6effb;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0c5de;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #687bfe;
  padding-top: 5px;
  padding-bottom: 5px;
}
@font-face {
  font-family: 'PublicSans';
  src: url(./assets/fonts/PublicSans/PublicSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PublicSansBold';
  src: url(./assets/fonts/PublicSans/PublicSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'PublicSansExtraBold';
  src: url(./assets/fonts/PublicSans/PublicSans-ExtraBold.ttf)
    format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

// global styles
@import './assets/styles/variables';
@import './assets/styles/mixin';

// styles of pages
@import './assets/styles/home';
// @import './assets/styles/landing';
@import './assets/styles/login';
@import './assets/styles/changepassword';
@import './assets/styles/reset';
@import './assets/styles/dashboard';
@import './assets/styles/propertylist';
@import './assets/styles/notificationlist';
@import './assets/styles/editproperty';
@import './assets/styles/addproperty';
@import './assets/styles/project';
@import './assets/styles/settings';
@import './assets/styles/preview';
@import './assets/styles/skeleton';
@import './assets/styles/profile';

// styles of components
@import './assets/styles/carousel';
@import './assets/styles/inputform';
@import './assets/styles/prosidebar';
@import './assets/styles/tile';
@import './assets/styles/dropdownbs';
@import './assets/styles/modalbs';
@import './assets/styles/burgermenu';
@import './assets/styles/paginate';
@import './assets/styles/daypicker';
@import './assets/styles/notification';
@import './assets/styles/collapse';
@import './assets/styles/sortby';
