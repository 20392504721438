.settings-body {
  // margin: 0 40px;
}

.settings-title {
  font-size: 32px;
  font-weight: 700;
  color: $dark-color;
  padding-top: 30px;
  padding-bottom: 10px;
}

.settings-block1 {
  margin-top: 20px;
  background-color: white;
}

.settings-hr {
  @include hr-style;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
}

.settings-block2 {
  margin-top: 18px;
  background-color: white;
}

.settings-block1-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.noti-content-title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 15px;
}

.notification-content-container {
  margin: 20px 0;
}

.noti-content-subtitle {
  padding: 0 40px;
}

.noti-content-body {
  @include flex-style(row, space-between, center);
}

.numberbox-group {
  margin-left: 40px;
  margin-top: 10px;
  @include flex-style(row, flex-start, center);
}

.numberbox-container {
  @include flex-style(row, space-between, center);
  padding: 0 10px;
  margin-right: 20px;
  background-color: $grey-color;
}

.numberbox-container label {
  padding: 5px 10px 5px 0px;
}

.numberbox-container input + label {
  margin-right: 0;
}

.input-custom-noti-setting {
  width: 70px;
  height: 38px;
  margin: 5px 15px 5px 0px;
  border: none;
  outline: none;
}

.switch-group {
  @include flex-style(row, flex-end, center);
}

.switch-container {
  @include flex-style(row, flex-start, center);
}

#first-switch-container {
  margin-right: 30px;
}

.noti-content-title-container {
  @include flex-style(row, flex-start, center);
}

.settings-input {
  border: none;
  outline: none;
  background-color: $grey-color;
  width: 100px;
  line-height: 36px;
  margin-left: 38px;
  padding-left: 15px;
}

.switch-label {
  margin-right: 15px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap;
}

.settings-input:focus {
}

.settings-loading-txt {
  @include flex-style(row, center, center);
  // margin-top: 400px;
  height: 700px;
  font-size: 24px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

@media screen and (max-width: 1280px) {
  .numberbox-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .numberbox-container {
    margin-right: 0px;
  }

  #first-numberbox {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .noti-content-body {
    flex-direction: column;
    align-items: flex-start;
  }

  .noti-content-subtitle {
    padding: 0 0px 20px 40px;
  }

  .switch-group {
    padding-left: 40px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .settings-body {
    margin: 0px;
  }

  .settings-title {
    padding: 40px 40px 10px 0px;
  }
}

@media screen and (max-width: 425px) {
  .switch-group {
    flex-direction: column;
    align-items: flex-end;
  }

  #first-switch-container {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}
