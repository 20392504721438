.tile-container {
  // outline: none;
  background: #ffffff;
  border: 1px solid #f2f5f9;
  box-shadow: 0px 14px 26px rgba(183, 188, 200, 0.11);
  border-radius: 4px;
  width: 100%;
  position: relative;
  // @include flex-style(column, flex-start, flex-start);
  // position: relative;
}

// .tile-skeleton-container {
//   width: 229px;
//   height: 313px;
//   outline: none;
//   border: none;
//   background-color: transparent;
//   border-radius: 4px;
//   @include flex-style(column, flex-start, flex-start);
//   position: relative;
// }

.property-photo-container {
  width: 100%;
  aspect-ratio: 23 / 14;
}

.tile-dropdown-container {
  position: absolute;
  top: 15px;
  left: 15px;
}

.tile-star-container {
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.property-content-container {
  padding: 12px 16px 18px;
}

.google-id-container {
  // margin-left: 12px;
  // margin-top: 15px;
  margin-bottom: 4px;
  font-size: 12px;
  color: black;
}

.google-id {
  font-size: 12px;
  color: #808080;
  margin-right: 4px;
}

.tile-property-name {
  color: #161d37;
  font-weight: 500;
  line-height: 20.8px;
  font-size: 16px;
  display: block;
  width: 100%;
  height: 42px;
}

.status-container {
  @include flex-style(row, left, center);
  margin-top: 15px;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  // width: 70%;
  // position: absolute;
  // left: 12px;
  // bottom: 63px;
  // height: 25px;
}

.active-container,
.price-container {
  display: flex;
}
.tile-btn-edge {
  background-color: #e6effb;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 9px;
}

.tile-btn-container {
  @include flex-style(row, space-between, center);
  margin-top: 20px;
}

.tile-btn-container .flair-btn {
  width: 50%;
  display: flex;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}

.ads-btn-ready {
  @include flex-style(row, space-between, center);
  padding-left: 22px;
  padding-right: 28px;
  width: 126px;
  height: 36px;
  border-radius: 100px;
  border: none;
  background-color: #e6effb;
  margin-right: 5px;
}

.ads-btn-ready:hover {
  color: white;
  background-color: $blue-color;
  position: relative;
  top: -1px;
}

.ads-btn-ready:active {
  color: rgba(255, 255, 255, 0.7);
  top: 0px;
}

.ads-btn-ready:hover .project-icon {
  background: url('../images/project-hover.svg');
}

.ads-btn-ready:active .project-icon {
  background: url('../images/project-active.svg');
}

.ads-btn:hover {
  color: white;
  background-color: $blue-color;
  top: -1px;
}

.project-icon {
  background: url('../images/project.svg');
  width: 18px;
  height: 18px;
  background-size: cover;
  border: none;
  margin-right: 3px;
}

.ads-btn-disable {
  @include flex-style(row, flex-start, center);
  padding-left: 11px;
  width: 126px;
  height: 36px;
  border-radius: 100px;
  border: none;
  background-color: #e6effb;
  opacity: 0.5;
}

.ads-btn:hover .project-icon {
  background: url('../images/project-hover.svg');
}

.ads-btn:active .project-icon {
  background: url('../images/project-active.svg');
}

.ads-btn:active {
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  top: 0px;
}

.edit-icon {
  background: url('../images/edit.svg');
  width: 18px;
  height: 18px;
  background-size: cover;
  border: none;
  margin-right: 7px;
}

.edit-btn {
  @include flex-style(row, flex-start, center);
  justify-content: right;
  padding: 0 20px;
  width: 88px;
  height: 36px;
  font-size: 12px;
  border-radius: 50%;
  color: $dark-color;
  border: none;
  background-color: transparent;
}

.edit-btn:hover {
  color: $blue-color;
  position: relative;
  top: -1px;
}

.edit-btn:active {
  opacity: 0.8;
  top: 0px;
}

.edit-btn-disable {
  @include flex-style(row, flex-start, center);
  padding-left: 11px;
  width: 114px;
  height: 36px;
  border-radius: 100px;
  border: none;
  background-color: transparent;
  opacity: 0.5;
}

.edit-btn:hover .edit-icon {
  background: url('../images/edit-hover.svg');
}

.edit-btn:active .edit-icon {
  background: url('../images/edit-active.svg');
}

.favorite-icon:hover {
  cursor: pointer;
}

.tooltip {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 130%;
  color: #161d37 !important;
  box-shadow: 0 3px 10px rgba(104, 123, 254, 0.15);
}

.tooltip span {
  text-align: left !important;
}

.tile-highlight-photo {
  // object-fit: cover;
  // overflow: hidden;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 640px) {
  .tile-container {
    display: flex;
    flex-direction: row;
    padding: 12px 12px 16px;
  }

  .property-photo-container {
    // height: inherit;
    width: 40%;
    margin-right: 12px;
  }

  .property-content-container {
    padding: 0;
    width: 60%;
  }

  .tile-property-name {
    // width: 100%;
  }

  .dropdown-selected {
    display: none;
  }

  .google-id-container {
    // margin-top: 3px;
  }

  .tile-highlight-photo {
    // object-fit: cover;
    // overflow: hidden;
    // width: 113px;
    // height: 146px;
    // border-radius: 5px;
  }

  .tile-star-container {
    left: 0;
    padding: 12px;
  }

  .tile-btn-container {
    // left: 135px;
    // bottom: 9px;
  }

  .status-container {
    // left: 135px;
    // bottom: 60px;
  }
}

@media screen and (max-width: 425px) {
  .property-photo-container {
    // width: 30%;
  }

  .property-content-container {
    // width: 70%;
  }
}
