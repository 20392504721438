@mixin flex-style($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin confirm-btn-style {
  color: white;
  border: none;
  outline: none;
  background: $blue-color;
  box-shadow: 0 5px 15px rgba(104, 123, 254, 0.35);
  border-radius: 30px;
  cursor: pointer;
}

@mixin confirm-btn-hover-style {
  background: $dark-color;
  position: relative;
  top: -1px;
}

@mixin confirm-btn-active-style {
  opacity: 0.8;
  top: 0px;
}

@mixin cancel-btn-style {
  color: $dark-color;
  border: none;
  outline: none;
  background: #e6effb;
  border-radius: 30px;
}

@mixin cancel-btn-hover-style {
  color: $blue-color;
  position: relative;
  top: -1px;
}

@mixin cancel-btn-active-style {
  opacity: 0.8;
  top: 0px;
}

@mixin property-has-img-input-style {
  height: 46px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border: 1.75px solid transparent !important;
  width: 100%;
  background-color: #f2f5f9;
}

@mixin property-has-img-input-active-style {
  border: 1.75px solid $blue-color !important;
  background-color: white;
  outline: none;
}

@mixin landing-btn-style {
  color: white;
  outline: none;
  border-radius: 4px;
  font-weight: bold;
}

@mixin landing-btn-hover-style {
  position: relative;
  top: -1px;
  color: $blue-color;
}

@mixin landing-btn-active-style {
  opacity: 0.8;
  top: 0px;
}

@mixin hr-style {
  height: 2px !important;
  color: $hr-color;
}

@mixin skeleton-color-style {
  --base-color: rgba(202, 220, 237, 0.7);
  --highlight-color: rgba(219, 230, 242, 1);
}
