.project-layout {
  // width: calc(100vw - 80px);
  // height: 100vh;
  // @include flex-style(column, flex-start, stretch);
  // overflow-x: hidden;
  // overflow-y: auto;
  background-color: $dashboard-bgcolor;
}

.project-header-container {
  background-color: white;
  width: calc(100vw - 80px);
  position: relative;
  left: -40px;
  padding: 32px 40px;
  border-top: 1px solid #e9ecf1;
  border-bottom: 1px solid #e9ecf1;
  display: flex;
  justify-content: space-between;
}

.project-header-container > a {
  color: black;
  text-decoration: none;
}

.project-header-container > a:hover {
  color: $dark-color;
  font-weight: 600;
  text-decoration: none;
}

.project-header-container > a:active {
  color: $dark-hover-color;
}

.project-header-hr {
  width: 100%;
  color: #6f728b;
  margin: 0;
  opacity: 0.13;
}

.project-header-body {
  @include flex-style(row, space-between, flex-start);
  position: relative;
  padding: 32px 40px;
  border-bottom: 1px solid #e9ecf1;
}

.project-header-content-container {
  @include flex-style(row, flex-start, flex-start);
}

.project-highlight-photo {
  object-fit: cover;
  aspect-ratio: 1 / 1;
  height: 145px;
  // overflow: hidden;
  border-radius: 8px;
  margin-right: 32px;
}

.project-header-content-info {
  margin-right: 40px;
  @include flex-style(row, flex-start, flex-start);
}

.project-header-content {
  // @include flex-style(column, flex-start, flex-start);
}

.project-header-content a {
  text-underline-offset: 3px;
  color: #161d37;
}

.project-status-container-tmp {
  // display: none;
  @include flex-style(row, flex-start, center);
  height: 34px;
  border-radius: 33px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.project-status-container {
  @include flex-style(row, flex-start, center);
  height: 34px;
  border-radius: 33px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.project-property-name {
  font-size: 32px;
  font-weight: 700;
  line-height: 130%;
  // height: 86px;
  // min-width: 300px;
  // max-width: 600px;
  // margin-right: 16px;
  // overflow: hidden;
}

.project-property-googleid {
  // margin-top: 8px;
  color: #515868;
  font-weight: 500;
  font-size: 16px;
  line-height: 20.8px;
}

.project-property-googleid span {
  // margin-left: 58px;
  color: #161d37;
}
.project-header-btn {
  width: 320px;
  height: 70px;
  @include flex-style(row, flex-end, flex-start);
  margin-top: 5px;
}

.disable-project-btn {
  @include cancel-btn-style;
  width: 47%;
  height: 36px;
}

.disable-project-btn:hover {
  @include cancel-btn-hover-style;
}

.disable-project-btn:active {
  @include cancel-btn-active-style;
}

.project-btn-container-mobile {
  // display: none;
}

.project-btn-container {
  // padding-top: 5px;
  margin-left: 10px;
}

.project-pause-btn {
  width: 160px;
  height: 42px;
  @include confirm-btn-style;
}

.project-pause-btn:hover {
  @include confirm-btn-hover-style;
}

.project-pause-btn:active {
  @include confirm-btn-active-style;
}

.project-budget-container {
  margin: 40px 0;
}

.project-budget-title {
  font-size: 28px;
  font-weight: 600;
}

.project-daypicker-btn {
  position: relative;
}

.calendar-dropdown-menu {
  background: transparent;
  border: none;
  width: 950px;
  position: absolute;
  right: -3px;
  top: 45px;
  @include flex-style(row, flex-end, flex-start);
}

.budget-body {
  display: flex;
  // margin: 24px 0 0 0;
  width: 100%;
}

.budget-value-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;
  height: inherit;
}

.value-budget-container {
  padding: 23.5px 26.67px;
  width: 233px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e9ecf1;
  @include flex-style(row, flex-start, center);
}

.value-spent-container {
  width: 100%;
  height: 98px;
  background-color: white;
  @include flex-style(row, space-start, center);
}

.value-balance-container {
  width: 100%;
  height: 98px;
  background-color: white;
  @include flex-style(row, space-start, center);
}

.budget-mark-img {
  margin-right: 18.67px;
}

.value-inner-container {
  @include flex-style(column, flex-start, flex-start);
  // margin-left: 18.67px;
}

.budget-inner-name {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 130%;
  font-weight: 400;
}

.budget-inner-value {
  font-size: 22px;
  font-weight: 600;
  line-height: 28.6px;
}

.budget-chart-container {
  padding: 1.5rem;
  width: 100%;
  background-color: white;
}

.chart-title-container {
  @include flex-style(row, flex-start, center);
  // display: grid;
  // align-content: center;
  // grid-template-columns: auto auto auto;
  // grid-gap: 7px;
}

.chart-title-detail {
  margin-right: 5px;
}

.barchartspent-container {
  // height: 236px;
  // aspect-ratio: 6 / 1;
  // width: calc(100% - 24px);
  // @include flex-style(row, center, center);
}

.chart-title {
  margin: 0 7px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  white-space: nowrap;
  // margin-right: 5px;
}

.chart-info-img {
  cursor: pointer;
  width: 16.67px;
  height: 16.67px;
}

.__tooltip {
  font-size: 16px !important;
  max-width: 350px !important;
  box-shadow: 0 3px 10px rgba(104, 123, 254, 0.15) !important;
  opacity: 1 !important;
}

.project-metrics-container {
  // height: 628px;
  // margin: 0 20px;
}

.metrics-container {
  // @include flex-style(row, space-between, center);
  // margin-bottom: 16px;
}

.project-metrics-title {
  font-size: 28px;
  font-weight: 600;
}

.metrics-chart-group {
  display: grid;
  // width: 100%;
  grid-gap: 20px;
  grid-template-columns: calc((100vw - 208px) / 3) calc((100vw - 208px) / 3) calc(
      (100vw - 208px) / 3
    );
  // grid-template-columns: repeat(
  //   auto-fit,
  //   minmax(, 1fr)
  // );
  // flex-wrap: wrap;
  // padding-bottom: 30px;
}

.metrics-chart-container {
  // padding: 0 8px 16px 8px;
  // width: 33.33333%;
  width: 100%;
  aspect-ratio: 442 / 229;
  background-color: white;
  // padding-bottom: 5px;
  padding: 16px;
  // width: 3000px;
  // grid-column: 1 / 1;
}

@media screen and (max-width: 1240px) {
  .metrics-chart-group {
    grid-template-columns: calc((100vw - 188px) / 2) calc((100vw - 188px) / 2);
  }
}

// @media screen and (max-width: 900px) {
//   .metrics-chart-group {
//     grid-template-columns: 100%;
//   }
//   .metrics-chart-container {
//     aspect-ratio: 335 / 165;
//   }
// }

.chart-header-container {
  @include flex-style(row, space-between, center);
  margin-bottom: 10px;
  // display: grid;
  // grid-template-columns: auto auto;
  // justify-content: space-between;
}

.chart-ratio-container {
  @include flex-style(row, flex-start, flex-end);
  font-weight: 600;
}

.chart-ratio-value {
  font-size: 22px;
  line-height: 28.6px;
}

.chart-ratio {
  color: #38d086;
  font-size: 14px;
  line-height: 16px;
}

.chart-ratio-diff-container {
  @include flex-style(row, flex-start, center);
}

.barchartimpression-container {
  height: 80%;
  @include flex-style(row, center, center);
  padding-right: 5px;
}

.linechartclicks-container {
  // @include flex-style(row, center, center);
  // padding-right: 5px;
  // display: block;
  // aspect-ratio: 3 / 1;
  // height: 100%;
}

.no-data-txt {
  font-size: 24px;
  font-weight: bold;
  color: rgba(230, 230, 230, 1);
  padding-bottom: 40px;
}

@media screen and (max-width: 1600px) {
  .budget-chart-container {
    // width: 84%;
  }
}

@media screen and (max-width: 1288px) {
  .project-property-name {
    // width: 449px;
  }
}

@media screen and (max-width: 1140px) {
  .project-property-name {
    // width: 409px;
  }

  .budget-body {
    // flex-direction: column;
  }

  .budget-value-group {
    // flex-direction: row;
    // align-items: flex-start;
    // width: 100%;
    // height: auto;
    // margin-bottom: 40px;
    // overflow-x: scroll;
  }

  .value-budget-container {
    // width: 220px;
  }

  .budget-mark-img {
    // margin-left: 40px;
    // margin-right: 20px;
  }

  .budget-chart-container {
    // width: 100%;
  }
}

@media screen and (max-width: 1080px) {
  .project-highlight-photo {
    // width: 190px;
    // height: 190px;
  }

  .project-header-content-info {
    flex-direction: column;
  }

  .project-header-content {
    // margin-right: 0px;
    // margin-bottom: 10px;
  }
}

@media screen and (max-width: 900px) {
  .project-header-body {
    flex-direction: column;
    // padding-bottom: 28px;
  }

  .project-header-content-info {
    margin-right: 0px;
  }

  .project-highlight-photo {
    // width: 200px;
    // height: 200px;
  }

  .project-property-name {
    // width: calc(100vw - 390px);
  }

  .project-status-container-tmp {
    width: 98%;
    justify-content: space-between;
  }

  .project-btn-container-mobile {
    // display: block;
    // margin-top: 8px;
  }

  .project-btn-container {
    margin-top: 30px;
    direction: rtl;
    // margin-top: -49px;
    // margin-left: 497px;
    // display: none;
  }

  .project-pause-btn {
    width: 159px;
    height: 42px;
  }

  .metrics-chart-group {
    grid-template-columns: 100%;
  }

  .metrics-chart-container {
    aspect-ratio: 335 / 145;
  }
}

@media screen and (max-width: 840px) {
  .value-budget-container {
    // width: 180px;
  }

  .budget-mark-img {
    // margin-left: 30px;
    // margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .project-header-content-container {
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .project-highlight-photo {
    width: 100%;
    height: auto;
    aspect-ratio: 335 / 125;
    margin-right: 0px;
    border-radius: 4px;
    // margin-bottom: 20px;
  }

  .project-header-content {
    // margin-top: 8px;
  }

  .project-property-name {
    // height: auto;
  }
  .project-status-container {
    position: absolute;
    left: 8px;
    top: 0;
  }

  .project-header-content-info {
    padding-left: 10px;
  }

  .value-budget-container {
    // width: 160px;
  }

  .budget-mark-img {
    // margin-left: 20px;
    // margin-right: 10px;
  }
  .project-layout {
    width: 100%;
  }

  .project-header-body {
    padding: 16px 20px 40px;
  }

  // .project-budget-container {
  //   margin: 40px 20px;
  // }

  .budget-chart-container {
    padding: 26px 25.4px;
    margin-top: 16px;
    width: 100%;
  }

  .budget-body {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .budget-value-group {
    flex-direction: row;
    height: auto;
  }

  .value-budget-container {
    flex-direction: column;
    padding: 19px 18px;
    width: 150px;
    margin-right: 12px;
    align-items: flex-start;
  }

  .value-inner-container {
    margin-top: 11px;
  }

  .budget-inner-name {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 660px) {
  .value-budget-container {
    // @include flex-style(column, flex-start, flex-start);
    // width: 120px;
    // height: auto;
    // padding-left: 20px;
  }

  .budget-mark-img {
    // margin-left: 0px;
    // margin-top: 20px;
    // margin-bottom: 10px;
  }

  .budget-inner-value {
    // margin-bottom: 20px;
  }
}

@media screen and (max-width: 900px) {
  .project-header-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .project-header-container {
    width: 100vw;
    left: -20px;
    padding: 16px 20px 40px;
  }
  .project-property-name {
    font-weight: 800;
    font-size: 24px;
    line-height: 31.2px;
  }
  .budget-value-group {
    // flex-direction: column;
  }

  .value-budget-container {
    // @include flex-style(row, flex-start, center);
    // padding: 20px 0px;
    // padding-left: calc(50% - 70px);
    // margin-right: 0px;
    // margin-bottom: 20px;
    // width: 100%;
    // height: auto;
  }

  .budget-mark-img {
    // margin: 0 20px 0 0px;
  }

  .value-inner-container {
    // margin-right: 0px;
  }

  .budget-inner-name {
    // margin-bottom: 10px;
  }

  .budget-inner-value {
    // margin-bottom: 0px;
  }
}

@media screen and (max-width: 375px) {
  // .project-budget-container {
  //   margin: 40px 20px;
  // }

  .project-property-name {
    // font-size: 24px;
    // font-weight: 800;
    // line-height: 130%;
  }

  .value-budget-container {
    // padding: 19px 18px;
    // width: 150px;
    // height: 115px;
    // margin-right: 12px;
  }

  .budget-inner-value {
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
  }

  .budget-chart-container {
    // padding: 26px 25px;
  }

  .project-metrics-title {
    font-weight: 700;
  }
}
