// .carousel-pane {
//   @include flex-style(column, flex-start, flex-start);
//   position: relative;
//   width: 50%;
//   background-color: $dark-color;
//   padding: 0 8.5%;
// }

.logo-right-container {
  margin-left: -12vw;
  width: 20vw;
}

.logo-right {
  // font-weight: bold;
  // font-size: 26px;
  // color: white;
  // position: relative;
  // left: calc(22.5vw - 241px);
}

.slick-dots {
  bottom: 0;
  // display: flex !important;
  // flex-direction: row;
  // width: 110px !important;
  // position: relative;
  // float: left;
  // padding: 0;
  // margin: 0;
  // list-style: none;
  text-align: left;
}

.slick-dots li {
  // position: relative;
  // display: inline-block;
  // min-width: 15%;
  transition: width 0.5s linear;
  // margin-right: 7%;
  width: 14px !important;
}

.slick-dots li.slick-active {
  // flex-grow: 1;
  width: 48px !important;
}

.slick-dots li:last-child {
  margin-right: 0%;
}

.slick-dots li button {
  width: 100%;
}

.slick-dots li button:before {
  width: 80%;
}

.slick-dots li.slick-active button:before {
  // width: 80%;
}

.slick-dots li button:before {
  width: 100%;
}

.slick-dots li.slick-active button:before {
  // width: 100%;
}

.slick-dots li button:before {
  // position: absolute;
  // top: 0;
  // left: 0;
  // /* margin-right: 24px; */
  // text-align: center;
  // opacity: .25;
  // /* width: 16px; */
  height: 8px;
  border-radius: 4px 4px 4px 4px;
  background-color: #b7bcc8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: transparent;
  /* transition: width 0.3s linear; */
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  // height: 8px;
  border-radius: 4px 4px 4px 4px;
  color: transparent;
  background-color: white;
}

.carousel-container {
  // margin-top: 160px;
  // width: 100%;
  // position: relative;
}

.carousel-item-body {
  color: white;
  padding-right: 10px;
}

.carousel-txt-header {
  // text-align: left;
  font-weight: 800;
  font-size: 40px;
  line-height: 52px;
  // margin-bottom: 35px;
  overflow-wrap: break-word;
  // color: white;
}

.carousel-txt-content {
  // text-align: left;
  font-weight: 500;
  font-size: 22px;
  line-height: 28.6px;
  margin-top: 4vh;
  margin-bottom: 10vh;
  // margin-bottom: 0.83vw;
  overflow-wrap: break-word;
  // color: white;
}

.inputform-header {
  margin-bottom: 0.55vw;
  font-size: 0.833vw;
  color: rgb(10, 10, 17);
}

.inputform-input-img {
  margin-left: -2.08vw;
  width: 1.319vw;
  height: 1.67vh;
}

@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .logo-right {
    // font-size: 24px;
    // position: relative;
    // left: 145px;
  }

  // .carousel-container {
  //   position: relative;
  //   left: 129px;
  // }

  .carousel-txt-header {
    // font-size: 46px;
  }

  .carousel-txt-content {
    // font-size: 22px;
  }
}

@media screen and (max-width: 1366px) {
  .carousel-txt-header {
    // font-size: 40px;
  }

  .carousel-txt-content {
    // font-size: 22px;
    // font-weight: 500;
    // line-height: 28.6px;
  }
}

@media screen and (max-width: 576px) {
  .carousel-txt-header {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    // margin-bottom: 20px;
  }

  .carousel-txt-content {
    font-size: 22px;
    line-height: 29px;
    font-weight: 500;
  }
}
