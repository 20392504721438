label.checkbox {
	@apply block relative mb-4 cursor-pointer select-none;

	input {
		@apply absolute opacity-0 cursor-pointer h-0 w-0;
	}

	span {
		@apply absolute top-0 left-0 h-4 w-4 rounded border border-[#B7BCC8] after:content-[''] after:hidden;
	}

	input:checked ~ span, input:indeterminate ~ span {
		@apply bg-[#687BFE] border-[#687BFE] after:block;
	}
	
	input:checked ~ span::after {
		@apply absolute left-1 top-[1.5px] w-[6px] h-2 border-white border-r-2 border-b-2 rotate-45;
	}
	
	input:indeterminate ~ span::after {
		@apply w-2 h-0.5 m-auto mt-1.5 bg-white;
	}

}