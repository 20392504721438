@mixin guide-container-style($position, $fontSize, $top) {
  position: $position;
  font-size: $fontSize;
  top: $top;
}

@mixin changepassword-pane-mobile-style {
  width: 100% !important;
  height: 710px !important;
}

@mixin change-rightside-mobile-style {
  width: 100vw;
  height: 720px;
}

.changepassword-layout {
  width: 100%;
  height: 100vh;
  @include flex-style(row, flex-start, stretch);
  overflow: hidden;
}

.changepassword-pane {
  width: 50%;
  @include flex-style(row, center, stretch);
}

.change-rightside {
  position: relative;
  width: 50%;
  @include flex-style(column, flex-start, center);
  background: url('../../assets/images/bg.svg');
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

.guide-container-group {
  margin-top: 12px;
}

.guide-container {
  display: flex;
  margin-top: 4px;
}

.guide-container1 {
  @include guide-container-style(relative, 20px, 0);
}

.line1 {
}

.guide {
  padding-left: 16px;
}

.guide-container2 {
  @include guide-container-style(relative, 20px, 45px);
}

.line2 {
}

.guide-container3 {
  @include guide-container-style(relative, 20px, 90px);
}

.line3 {
}

.inputform-container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 1357px) and (max-width: 1481px) {
  .guide-container2 {
    @include guide-container-style(relative, 20px, 35px);
  }

  .guide-container3 {
    @include guide-container-style(relative, 20px, 100px);
  }

  .inputform-container {
    // margin-top: 115px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1356px) {
  .guide-container2 {
    @include guide-container-style(relative, 20px, 35px);
  }

  .guide-container3 {
    @include guide-container-style(relative, 20px, 100px);
  }

  .inputform-container {
    // margin-top: 155px;
  }
}

@media screen and (min-width: 807px) and (max-width: 899px) {
  .guide-container2 {
    @include guide-container-style(relative, 20px, 35px);
  }

  .guide-container3 {
    @include guide-container-style(relative, 20px, 130px);
  }

  .inputform-container {
    // margin-top: 180px;
  }
}

@media screen and (min-width: 768px) and (max-width: 806px) {
  .guide-container2 {
    @include guide-container-style(relative, 20px, 55px);
  }

  .guide-container3 {
    @include guide-container-style(relative, 20px, 140px);
  }

  .inputform-container {
    // margin-top: 185px;
  }
}

@media screen and (min-width: 649px) and (max-width: 768px) {
  .changepassword-layout {
    @include flex-style(column, space-between, stretch);
    height: 1225px;
  }

  .changepassword-pane {
    @include changepassword-pane-mobile-style;
  }

  .change-rightside {
    @include change-rightside-mobile-style;
  }

  .guide-container1 {
    @include guide-container-style(relative, 20px, 15px);
  }

  .guide-container2 {
    @include guide-container-style(relative, 20px, 55px);
  }

  .guide-container3 {
    @include guide-container-style(relative, 20px, 95px);
  }

  .inputform-container {
    // margin-top: 120px;
  }
}

@media screen and (min-width: 594px) and (max-width: 648px) {
  .changepassword-layout {
    @include flex-style(column, space-between, stretch);
    height: 1225px;
  }

  .changepassword-pane {
    @include changepassword-pane-mobile-style;
  }

  .change-rightside {
    @include change-rightside-mobile-style;
  }

  .guide-container1 {
    @include guide-container-style(relative, 20px, 10px);
  }

  .guide-container2 {
    @include guide-container-style(relative, 20px, 45px);
  }

  .guide-container3 {
    @include guide-container-style(relative, 20px, 105px);
  }

  .inputform-container {
    // margin-top: 130px;
  }
}

@media screen and (min-width: 394px) and (max-width: 593px) {
  .changepassword-layout {
    @include flex-style(column, space-between, stretch);
    height: 1225px;
  }

  .changepassword-pane {
    @include changepassword-pane-mobile-style;
  }

  .change-rightside {
    @include change-rightside-mobile-style;
  }

  .guide-container1 {
    @include guide-container-style(relative, 20px, 10px);
  }

  .guide-container2 {
    @include guide-container-style(relative, 20px, 40px);
  }

  .guide-container3 {
    @include guide-container-style(relative, 20px, 100px);
  }

  .inputform-container {
    // margin-top: 140px;
  }
}

@media screen and (max-width: 393px) {
  .changepassword-layout {
    @include flex-style(column, space-between, stretch);
    height: 1225px;
  }

  .changepassword-pane {
    @include changepassword-pane-mobile-style;
  }

  .change-rightside {
    @include change-rightside-mobile-style;
  }

  .guide-container1 {
    @include guide-container-style(relative, 20px, 10px);
  }

  .guide-container2 {
    @include guide-container-style(relative, 20px, 40px);
  }

  .guide-container3 {
    @include guide-container-style(relative, 20px, 130px);
  }

  .inputform-container {
    // margin-top: 175px;
  }
}
