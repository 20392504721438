h2 {
  @apply text-[24px] font-extrabold;
}

h5 {
  @apply text-[32px] leading-[41.6px] font-bold;
}

p {
  @apply font-normal text-[16px] leading-6;
}

.copyright {
  @apply text-[#707787] text-[14px] leading-[18.2px] font-normal;
}

/* custom flair input */
.input-basic {
  @apply rounded hover:border-[#687BFE] focus:border-[#687BFE] focus:bg-white focus-visible:outline-none focus:placeholder:text-transparent w-full disabled:border-none;
}

.flair-search {
  @extend .input-basic;
  @apply py-[13px] rounded bg-white px-12 border border-[#EBEDF1F2] text-[15px] leading-[19.5px] font-normal;
}

.flair-input {
  @extend .input-basic;
  @apply font-normal text-base leading-6 bg-[#F2F5F9] py-[11px] px-4 border-[1.5px] border-[#F2F5F9] hover:bg-white disabled:bg-[#F2F5F9] disabled:opacity-70;
}

.flair-input-icon {
  @apply h-full absolute left-[18px] top-0 flex items-center;
}

/* checkbox */
.check-container {
  @apply block relative pl-[25px] mb-3 cursor-pointer text-[14px] leading-[18.2px] hover:text-[#687bfe] select-none;

  input {
    @apply absolute opacity-0 cursor-pointer h-0 w-0;
  }

  .checkmark {
    @apply absolute top-0 left-0 h-[18px] w-[18px] rounded border-[2px] border-[#8C94A3] after:content-[''] after:absolute after:hidden after:left-[5px] after:top-[1px] after:w-[5px] after:h-[10px] after:border-r-[3px] after:border-b-[3px] after:rotate-45;
  }

  input:checked ~ .checkmark {
    @apply bg-[#687bfe] border-[#687bfe] after:block;
  }
}

// radio box
.radio-container {
  @apply block relative cursor-pointer select-none pr-8;
  /* Hide the browser's default radio button */
  input {
    @apply absolute opacity-0 cursor-pointer w-[17px] h-3 top-0 right-0 z-40;
  }
  /* Create a custom radio button */
  /* Create the indicator (the dot/circle - hidden when not radioed) */
  .radiomark {
    @apply absolute top-0 right-0 w-[17px] h-3 bg-transparent border-none outline-none after:content-[''] after:absolute after:hidden after:top-[2px] after:right-0 after:w-[8px] after:h-[15px] after:border-r-[3px] after:border-b-[3px] after:border-[#687bfe] after:rotate-45;
  }
  /* When the radio button is radioed, add a blue background */
  /* Show the indicator (dot/circle) when radioed */
  input:checked ~ .radiomark {
    @apply bg-white after:block;
  }
  label {
    @apply hover:text-[#687bfe] cursor-pointer;
  }
  input:checked ~ label {
    color: #687bfe;
  }
  label {
    @apply cursor-pointer;
  }
}

.radiobox2-container {
  @apply block relative select-none hover:text-[#687bfe] pl-8;
  /* Hide the browser's default radio button */
  input {
    @apply absolute opacity-0 cursor-pointer w-5 h-5 top-0 left-0 z-40;
  }
  /* Create a custom radio button */
  /* Create the indicator (the dot/circle - hidden when not radioed) */
  .radiomark2 {
    @apply absolute top-[2px] left-0 w-5 h-5 bg-white border border-[#B7BCC8] rounded-full outline-none after:content-[''] after:absolute after:hidden after:top-1 after:left-1 after:w-[10px] after:h-[10px] after:bg-white after:rounded-full;
  }
  /* When the radio button is radioed, add a blue background */
  /* Show the indicator (dot/circle) when radioed */
  input:checked ~ .radiomark2 {
    @apply bg-[#687bfe] after:block;
  }
  input:checked ~ label {
    color: #687bfe;
  }
  label {
    @apply cursor-pointer;
  }
}

.flair-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  line-height: 110%;
  border-radius: 32px;
}

/* button size */
.flair-btn-lg {
  height: 42px;
  font-size: 16px;
}
.flair-btn-md {
  height: 42px;
  font-size: 14px;
}
.flair-btn-sm {
  height: 36px;
  font-size: 12px;
}

/* button type */
.flair-btn-primary {
  background-color: #687bfe;
  color: white;
  box-shadow: 0px 5px 15px 0px #687BFE40;
  &:hover {
    background-color: #8897FF;
  }
  &:focus {
    background-color: #5167FF;
  }
  &:disabled {
    opacity: .5;
  }
}
.flair-btn-secondary {
  background-color: #E6EFFB;
  color: #161D37;
  &:hover {
    background-color: #F5FAFF;
    color: #687BFE;
  }
  &:focus {
    color: #687BFE;
  }
  &:disabled {
    opacity: .5;
  }
}
.flair-btn-tertiary {
  @apply text-[#161D37] hover:text-[#687BFE] focus:opacity-80 disabled:opacity-50;
}
.flair-btn-quaternary {
  @apply border border-[#CED3DB] hover:border-[#161D37] focus:border-[#687BFE] focus:text-[#687BFE] disabled:opacity-50;
}

.white-background {
  @apply bg-white p-5 pb-10 mt-6 xs:pb-10 shadow-[0_20px_38px_rgba(183,188,200,0.11)] xs:p-8;
}

.property-tiles-container {
  @apply grid gap-x-[30px] gap-y-5;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}

.tab-panel {
  @apply hidden xs:block;
}

// segmented control
.segmented-control label {
  @apply leading-8;
}

.skeleton-background {
  background: linear-gradient(
    90.45deg,
    #dbe6f2 0%,
    #e1ecfa 48.44%,
    rgba(232, 241, 254, 0.4) 82.4%,
    rgba(225, 236, 250, 0.5) 95.88%
  );
  border-radius: 4px;
}
