.notification-component {
  @include flex-style(row, flex-start, center);
  margin: 5px 0px;
}

.noti-checkbox-container {
  padding: 5px 25px 0 0px;
}

.noti-checkbox {
  width: 20px;
  height: 20px;
  background-color: $dashboard-bgcolor;
}

.noti-checkbox:hover {
  cursor: pointer;
}
