.login-layout {
  display: flex;
}

.pane-container {
  width: 50%;
  height: 100vh;
  overflow: hidden;
  min-height: 640px;
}

.carousel-background {
  // background: url('/assets/images/mask.png') right bottom no-repeat;
  // background-size: 70%, auto;
  background-color: $dark-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo-left,
.logo-right {
  display: flex;
  align-items: flex-end;
}

.logo-left {
  display: none;
}

.logo-right {
  margin-bottom: 14vh;
}

.login-pane,
.carousel-pane {
  @include flex-style(column, space-between, stretch);
  // height: inherit;
  width: 440px;
  margin: auto;
  // min-height: 700px;
}

.login-pane {
  height: 100vh;
  padding-top: 21vh;
  padding-bottom: 7vh;
}

.carousel-pane {
  justify-content: flex-start;
  padding-top: 8vh;
  // height: 63vh;
}

.copyright-left,
.copyright-right {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: #707787;
}

.copyright-left {
  margin-top: 20px;
}

.copyright-right {
  display: none;
}

.forgot-password-container {
  @include flex-style(row, space-between, stretch);
  width: 100%;
  margin-top: 42px;
}

@media screen and (max-width: 576px) {
  .forgot-password-container {
    margin-top: 27px;
  }
}

.login-container {
  @include flex-style(column, flex-start, stretch);
  // width: 440px;
  // margin-bottom: 96px;
}

.login-body-container {
  // @include flex-style(column, flex-start, stretch);
  // width: 100%;
}

// .loginbody-space1 {
//   flex-grow: 2.9;
// }

// .loginbody-space2 {
//   flex-grow: 0;
// }

// .loginbody-space3 {
//   flex-grow: 4.2;
// }

// .loginbody-space4 {
//   flex-grow: 1.1;
// }

.login-body-container button {
  font-size: 16px;
  line-height: 17.6px;
  font-weight: 600;
}

.login-subtitle {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #222335;
  margin-top: 16px;
}

.forgot-password {
  font-size: 16px;
  font-weight: 600;
  color: $dark-color;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 3px;
}

.forgot-password:hover {
  color: $blue-color;
  position: relative;
  top: -1px;
}

.forgot-password:active {
  color: rgba(104, 122, 254, 0.8);
  position: relative;
  top: 0px;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.forgot-password-container .checkmark {
  position: absolute;
  top: 1px;
  left: 2px;
  height: 20px;
  width: 20px;
  background-color: $grey-color;
  border: transparent 1px solid;
}

/* On mouse-over, drop shaddow */
.checkbox-container:hover input ~ .checkmark {
  // box-shadow: 0 0 5px rgba(104, 123, 254, 0.35);
}

.checkbox-container input:focus ~ .checkmark {
  // box-shadow: 0 0 10px rgba(104, 123, 254, 0.35);
  border: $blue-color 1px solid;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background: $blue-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-container input ~ .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mask-img {
  align-self: flex-end;
  width: 60%;
  // overflow: hidden;
  // height: 30vh;
  // position: absolute;
  // width: 34vw;
  // bottom: 0;
  // right: 0;
}

.mask-mobile-img {
  display: none;
  // position: absolute;
  // width: 65vw;
  // height: 40vw;
  // bottom: 100px;
}

// .login-form {
//   width: 440px;
//   margin: auto;
// }

.login-btn {
  @include confirm-btn-style;
  width: 100%;
  height: 52px;
  font-weight: bold;
  margin-top: 32px;
}

.login-btn:hover {
  @include confirm-btn-hover-style;
}

.login-btn:active {
  @include confirm-btn-active-style;
}

.login-btn:focus {
  @include confirm-btn-hover-style;
}

.login-title {
  line-height: 52px;
  font-size: 40px;
  font-weight: 800;
  color: #222335;
}

// .shape-image {
//   display: flex;
//   justify-content: right;
//   // background: url('/assets/images/mask.png') right bottom;
// }

// .carousel-pane-space1 {
//   flex-grow: 4;
// }

// .carousel-pane-space2 {
//   flex-grow: 8;
// }

// .carousel-pane-space3 {
//   flex-grow: 18;
// }

// .carousel-pane-space4 {
//   flex-grow: 4;
// }

@media screen and (min-width: 1440px) {
  .login-pane,
  .carousel-pane {
    width: 61%;
  }
}

@media screen and (max-width: 992px) {
  .login-layout {
    flex-direction: column;
  }

  .pane-container {
    width: 100vw;
    height: auto;
    // height: 621px;
    // min-height: 621px;
    // padding: 9% 20% 6%;
  }

  // .carousel-background {
  //   background: url('/assets/images/mask-mobile.png') no-repeat center bottom 11%;
  //   background-size: auto 30%;
  //   background-color: $dark-color;
  // }

  .logo-left {
    display: flex;
    margin-bottom: 80px;
  }

  .logo-right {
    display: none;
  }

  .login-pane,
  .carousel-pane {
    width: 61%;
    // height: 621px;
  }

  .login-pane {
    justify-content: left;
    padding: 0px;
    margin: 32px auto 96px;
    height: auto;
  }

  .carousel-pane {
    padding-top: 78px;
    padding-bottom: 48px;
    // height: 100%;
    // padding-top: 19vw;
    // padding-bottom: 4vw;
  }

  .copyright-right {
    display: block;
  }

  .copyright-left {
    display: none;
  }

  // .login-container {
  //   @include flex-style(column, flex-start, stretch);
  //   // width: 65%;
  // }

  // .loginbody-space1 {
  //   flex-grow: 1.5;
  // }

  // .loginbody-space2 {
  //   flex-grow: 2;
  // }

  // .loginbody-space3 {
  //   flex-grow: 3.5;
  // }

  // .loginbody-space4 {
  //   flex-grow: 0;
  // }

  // .logo-left {
  //   display: block;
  // }

  .mask-img {
    display: none;
    // position: absolute;
    // width: 33vw;
    // bottom: 0;
    // right: 0;
  }

  .mask-mobile-img {
    display: block;
    height: 200px;
    align-self: flex-end;
    // position: absolute;
    // width: 65vw;
    // height: 40vw;
    // bottom: 11px;
    // right: 30px;
  }

  // .footer-left-txt {
  //   display: none;
  // }

  // .carousel-pane-space1 {
  //   flex-grow: 3;
  // }

  // .carousel-pane-space2 {
  //   flex-grow: 5;
  // }

  // .carousel-pane-space3 {
  //   flex-grow: 9;
  // }

  // .carousel-pane-space4 {
  //   flex-grow: 3;
  // }

  // .carousel-pane .footer-right-txt {
  //   display: block;
  // }

  .login-btn {
    // margin-bottom: 96px;
  }
}

@media screen and (max-width: 768px) {
  .login-pane,
  .carousel-pane {
    width: 70%;
  }
}

@media screen and (max-width: 576px) {
  .login-pane,
  .carousel-pane {
    width: 80%;
  }
}

@media screen and (max-width: 425px) {
  .pane-container {
    // padding: 9% 10%;
    // min-height: 600px;
  }

  .login-pane,
  .carousel-pane {
    width: 90%;
  }

  .login-pane {
    // padding-top: 9vw;
    // height: 600px;
    // padding-bottom: 26vw;
  }

  .carousel-pane {
    // height: 100%;
    // padding-top: 19vw;
    // padding-bottom: 12vw;
  }

  .login-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
  }

  .login-subtitle {
    font-size: 16px;
    margin: 15px 0px;
  }
}
