.preview-body-layout {
  // padding: 0 40px
}

.preview-title-container {
  @include flex-style(row, space-between, center);
  margin: 20px 0;
}

.preview-title {
  font-size: 28px;
  font-weight: bold;
}

.preview-content-container {
  background: white;
  padding: 30px 30px 10px 30px;
}

.carousel-preview-container {
  @include flex-style(column, flex-start, stretch);
}

.preview-img {
  border-radius: 4px;
}

.preview-content-block1 {
  @include flex-style(row, space-between, flex-start);
  margin-bottom: 30px;
}

.preview-edit-btn {
  @include cancel-btn-style;
  width: 150px;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
}

.preview-edit-btn:hover {
  @include cancel-btn-hover-style;
}

.preview-edit-btn:active {
  @include cancel-btn-active-style;
}

.carousel .thumb.selected {
  border: none !important;
  filter: brightness(1);
  transform: scale(1.1);
}

.carousel .thumb:hover {
  border: none !important;
  filter: brightness(1);
}

.carousel .thumbs {
  // @include flex-style(row, flex-start, center);
  height: 60px;
  margin-bottom: 0;
  padding: 6px 7px !important;
}

.carousel .thumb {
  border: none !important;
  padding: 0 !important;
  // margin-right: 20px !important;
  filter: brightness(0.6);
}

.carousel .thumbs-wrapper {
  // margin: 20px 10px !important;
}

.carousel-root {
  margin-right: 30px;
  width: 501px;
}

.carousel-root ul {
  padding: 0 !important;
  // @include flex-style(row, space-between, center);
}

.preview-hr1 {
  @include hr-style;
  margin: 30px 0;
  display: none;
}

.preview-txt-container {
  @include flex-style(row, space-between, stretch);
}

.preview-txt-body-container {
  @include flex-style(row, flex-start, flex-start);
}

.preview-selectproperty-dropdown-container {
  position: relative;
}

.preview-selectproperty-dropdown {
  @include flex-style(column, flex-end, flex-start);
  box-shadow: 4px 4px 8px rgba(34, 35, 53, 0.2);
  background: white;
  border: none;
  width: 304px;
  position: absolute;
  right: 0px;
  top: 44px;
  padding: 20px 0 20px 20px;
  z-index: 999;
  border-radius: 8px;
}

.preview-property-name {
  font-size: 24px;
  font-weight: bold;
  // white-space: nowrap;
  max-width: 30vw;
  margin-bottom: 10px;
}

.preview-txt-subname {
  opacity: 0.7;
}

.preview-txt-title {
  font-weight: 500;
  line-height: 130%;
  margin: 10px 0px;
  font-size: 16px;
}

.preview-txt-content {
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 20px;
  font-size: 18px;
  // padding-left: 15px;
}

.preview-txt-content a {
  color: $dark-color;
}

.preview-txt-content a:hover {
  // font-weight: bold;
  color: violet;
  // position: relative;
  // top: -1px;
}

.preview-txt-content a:active {
  // top: 0px;
}

.preview-project-container {
  @include flex-style(row, flex-start, center);
}

.preview-project-container a {
  line-height: 100%;
  text-decoration: none;
  border-bottom: 1px solid grey;
  padding-bottom: 0px;
  color: #707787;
  position: relative;
  top: 2px;
  font-weight: bold;
}

.preview-project-container a:hover {
  color: violet;
  top: 1px;
}

.preview-project-container a:active {
  font-weight: bold;
  position: relative;
  top: 2px;
}

.floorplan-item {
  padding: 0 0px 10px 15px;
}

.preview-property-name-container {
}

.preview-txt-left {
  margin-right: 5vw;
}

.preview-hr2 {
  @include hr-style;
  margin: 30px 0;
  width: calc(100vw - 756px);
}

.preview-hr {
  @include hr-style;
  margin: 30px 0;
  width: calc(100vw - 222px);
}

.preview-content-block2 {
  @include flex-style(row, flex-start, flex-start);
}

.preview-block2-left-container {
  margin-right: 35px;
}

.preview-footer-btn-group {
  @include flex-style(row, space-between, center);
  margin-top: 40px;
  padding-bottom: 40px;
}

.preview-footer-btn-group a {
  text-decoration: none;
}

.preview-btn-name {
  line-height: 100%;
  font-size: 18px;
}

.preview-footer-prev-btn {
  border: none;
  outline: none;
  background: transparent;
  height: 48px;
  @include flex-style(row, flex-start, center);
}

.preview-footer-prev-btn:hover {
  position: relative;
  top: -1px;
  font-weight: bold;
}

.preview-footer-prev-btn:active {
  top: 0px;
}

.preview-footer-next-btn {
  border: none;
  outline: none;
  background: transparent;
  height: 48px;
  @include flex-style(row, flex-end, center);
}

.preview-footer-next-btn:hover {
  position: relative;
  top: -1px;
  font-weight: bold;
}

.preview-footer-next-btn:active {
  position: relative;
  top: 0px;
}

.preview-radio-container {
  display: block;
  position: relative;
  padding-left: 27px;
  padding: 2px 0 5px 27px;

  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.preview-radio-container .radiobox-label {
  white-space: nowrap;
}

.preview-radio-container .radiobox-label:hover {
  color: $blue-color;
  cursor: pointer;
}

.preview-radio-container .radiobox:checked + label {
  color: $blue-color;
  font-weight: bold;
}

/* Hide the browser's default radiobox */
.preview-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
  left: 0px;
  top: 3px;
  z-index: 10;
}

/* Create a custom radiobox */
.preview-radio-container .radiomark {
  position: absolute;
  top: 3px;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: solid 1px rgba(22, 29, 55, 0.15);
  border-radius: 20px;
}

/* On mouse-over, drop shaddow */
.radiobox-label:hover input ~ .radiomark {
}

.radiobox-label input:focus ~ .radiomark {
}

/* When the radiobox is checked, add a blue background */
.preview-radio-container input:checked ~ .radiomark {
  background: $blue-color;
}

/* Show the radiomark when checked */
.preview-radio-container input:checked ~ .radiomark:after {
  display: block;
}

/* Style the radiomark/indicator */
.preview-radio-container .radiomark:after {
  content: '';
  position: absolute;
  display: none;
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  border: solid white;
  border-width: 5px;
  border-radius: 5px;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
}

.preview-propertynames-group {
  overflow: auto;
  border: 1px solid rgba(22, 29, 55, 0.15);
  height: 275px;
  padding: 7px 15px;
  width: 94%;
  position: relative;
  left: 0;
}

.preview-searchbox-container {
  position: relative;
  margin: 5px 0 10px 0;
  left: 0;
  width: 93.5%;
}

.preview-searchbox {
  background-color: $grey-color;
  padding-left: 35px;
  line-height: 36px;
  font-size: 16px;
  border: 1px solid transparent !important;
  width: 100%;
}

.preview-searchbox:focus {
  border: 1px solid rgba(22, 29, 55, 0.2) !important;
  background-color: white;
  outline: none;
}

.preview-amenities-group {
  width: 500px;
  padding-top: 10px;
  padding-left: 10px;
}

.preview-amenity-name {
  background: $dashboard-bgcolor;
  line-height: 250%;
  font-weight: 600;
  font-size: 14px;
  border-radius: 20px;
  padding: 0 20px;
  display: inline-block;
  margin-right: 20px;
}

.preview-highlight-photo {
  object-fit: cover;
  width: 507px;
  height: 311px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.25);
  margin-right: 20px;
  margin-bottom: 48px;
}

.preview-highlight-photo-area {
  width: 507px;
  height: 311px;
  background-color: $grey-color;
  margin-right: 30px;
  border-radius: 8px;
  // border: #cccccc dashed 1px;
  @include flex-style(row, center, center);
}

.disable-cancel-btn {
  width: 100px;
  height: 36px;
  border: none;
  outline: none;
  text-decoration: underline;
  background-color: transparent;
  margin-right: 10px;
  position: relative;
}

@media screen and (max-width: 1280px) {
  .preview-txt-body-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .preview-txt-left {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1160px) {
  .preview-txt-container {
    flex-direction: column;
  }

  .preview-property-name-container {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .preview-amenities-group {
    width: 45vw;
  }

  .preview-hr2 {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .preview-hr1 {
    display: block;
  }

  .preview-content-block1 {
    flex-direction: column;
    align-items: stretch;
  }

  .preview-highlight-photo {
    margin-right: 0px;
    width: auto;
  }

  .preview-highlight-photo-area {
    width: auto;
    margin-right: 0px;
  }

  .preview-property-name {
    max-width: 100%;
  }

  .preview-content-block2 {
    flex-direction: column;
  }

  .preview-block2-left-container {
    margin-right: 0px;
  }

  .preview-amenities-group {
    width: 100%;
  }

  .preview-content-container {
    padding-bottom: 30px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 576px) {
  .preview-title-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0px;
  }

  .preview-title {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .preview-selectproperty-dropdown {
    left: 0 !important;
  }

  .preview-edit-btn {
    width: 100%;
    height: 52px;
    font-size: 20px;
  }

  .preview-hr {
    width: auto;
  }
}

@media screen and (max-width: 576px) {
  .preview-body-layout {
    padding: 0px;
  }

  .preview-title-container {
    padding: 0px 0px;
  }

  .preview-content-container {
    padding: 14px;
  }

  .preview-highlight-photo {
    width: 100%;
    height: auto;
  }

  .preview-property-name {
    font-size: 22px;
    font-weight: 500;
    line-height: 28.6px;
  }

  .preview-edit-btn {
    font-size: 14px;
    font-weight: 600;
    line-height: 15.4px;
    height: 42px;
  }

  .preview-txt-title {
    font-weight: 600;
  }
}
