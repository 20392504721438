.comparebox-container {
  background-color: white;
  width: 250px;
  height: 100px;
  @include flex-style(row, flex-start, center);
}

.dashboard-hr {
  margin-left: 0;
  @include hr-style;
}

.dashboard-noti-title {
  margin-top: 24px;
  margin-bottom: 20px;
}

.dashboard-prop-title {
  padding-top: 24px;
  padding-bottom: 20px;
}

.dashboard-block1-group {
  @include flex-style(row, space-between, center);
}

.dashboard-block1-container {
  @include flex-style(row, space-between, center);
}

.dashboard-block1-right-container {
  @include flex-style(row, space-between, center);
  width: 200px;
}

.addproperty-btn-container {
  margin-left: 20px;
}

.addproperty-btn {
  @include confirm-btn-style;
  @include flex-style(row, space-between, center);
  padding: 0 15px;
  width: 150px;
  height: 40px;
}

.addproperty-btn:hover {
  @include confirm-btn-hover-style;
}

.addproperty-btn:active {
  @include confirm-btn-active-style;
}

.addproperty-icon {
  background: url('../images/addproperty.svg');
  width: 18px;
  height: 18px;
  background-size: cover;
  border: none;
}

.addproperty-btn:hover .addproperty-icon {
  background: url('../images/addproperty.svg');
}

.addproperty-btn:active .addproperty-icon {
  background: url('../images/addproperty-active.svg');
}

.dashboard-compare-container {
  @include flex-style(row, space-between, center);
  margin-top: 30px;
}

.compare-draft-container {
  @extend .comparebox-container;
}

.compare-disabled-container {
  @extend .comparebox-container;
}

.compare-declined-container {
  @extend .comparebox-container;
}

.compare-disapproved-container {
  @extend .comparebox-container;
}

.compare-img {
  padding: 15px;
}

.compare-count {
  font-size: 24px;
  font-weight: 600;
}

.comparebox-title {
  opacity: 0.7;
}

.compare-sup {
  color: $blue-color;
  font-size: 14px;
}

.user-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  margin-left: 10px;
}

.dashboard-title-container {
  @include flex-style(row, space-between, center);
  // margin-top: 60px;
}

.dashboard-title {
  font-size: 32px;
  font-weight: 700;
  color: $dark-color;
  padding-top: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.dashboard-noti-container {
  // padding: 0 40px;
}

.no-noti-container {
  @include flex-style(column, flex-start, center);
  padding: 40px;
}

.no-notification-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0px;
}

.no-noti-subtitle {
  text-align: center;
}

.notification-info-container {
  height: 200px;
  // margin-top: 20px;
  overflow-y: auto;
}

.noti-thumb-img-container {
  position: relative;
}

.noti-thumb-img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: relative;
}

.noti-time {
  opacity: 0.7;
  white-space: nowrap;
}

.property-group-container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.no-property-group-container {
  background: white;
  // height: 300px;
}

.tile-flex-container {
  // width: 20%;
  width: 100%;
}

.header-locate {
  position: sticky;
  top: 0px;
  z-index: 1001;
}

.header-container {
  @include flex-style(row, space-between, center);
  height: 60px;
  background: $dashboard-bgcolor;
  position: relative;
  width: calc(100vw - 80px);
  left: -40px;
  padding: 0 40px;
  // border-bottom: $hr-color solid 1px;
}

.header-container a {
  color: $dark-color;
  text-decoration: none;
}

.header-container a:hover {
  font-weight: bold;
  // position: relative;
  // top: -1px;
}

.header-container a:active {
  // top: 0px;
}

.user-info-btn {
  border: none;
  outline: none;
  background: transparent;
  @include flex-style(row, flex-end, center);
  margin-right: 40px;
}

.dashboard-noti-loading-txt {
  @include flex-style(row, center, center);
  height: 220px;
  // margin-top: 155px;
  font-size: 24px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.dashboard-prop-loading-txt {
  @include flex-style(row, center, center);
  height: 400px;
  // margin-top: 155px;
  font-size: 24px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.dashboard-prop-container {
  // padding: 0 40px;
}

.no-prop-container {
  @include flex-style(column, flex-start, center);
  padding: 80px 40px;
}

@media screen and (max-width: 1420px) {
  .property-group-container {
    grid-template-columns:
      calc((100vw - 258px) / 4) calc((100vw - 258px) / 4)
      calc((100vw - 258px) / 4) calc((100vw - 258px) / 4);
  }
}

@media screen and (max-width: 1160px) {
  .property-group-container {
    grid-template-columns:
      calc((100vw - 228px) / 3) calc((100vw - 228px) / 3)
      calc((100vw - 228px) / 3);
  }
}

@media screen and (max-width: 900px) {
  .property-group-container {
    grid-template-columns: calc((100vw - 198px) / 2) calc((100vw - 198px) / 2);
  }
}

@media screen and (max-width: 660px) {
  .property-group-container {
    grid-template-columns: auto;
    grid-gap: 16px 0;
  }
  .addproperty-btn-container {
    margin: 20px auto 0;
  }
}

@media screen and (max-width: 576px) {
  .dashboard-block1-group {
    padding: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: auto;
  }

  .header-container {
    width: 100vw;
    left: -20px;
    padding: 0 20px;
  }

  .dashboard-title {
    // margin-left: 0;
    // padding: 40px 40px 10px 40px;
  }

  .dashboard-subtitle {
    // padding: 0 40px;
  }

  .dashboard-noti-title {
    // padding: 0 40px;
  }

  .dashboard-prop-title {
    // padding: 0 40px;
  }
}

@media screen and(max-width: 375px) {
  .dashboard-prop-container {
    // padding: 0 20px;
  }

  .dashboard-prop-title {
    padding: 0;
    margin-bottom: 18px;
  }
}
