.flair-page-layout {
  // width: 100vw;
  // height: 100vh;
  // @include flex-style(row, space-between, stretch);
  // background-color: $dashboard-bgcolor;
}

.sidebar-container {
  // height: 100vh;
  width: 80px;
}

.page-body-layout {
  // width: calc(100vw - 80px);
  // overflow-x: hidden;
  // padding: 0 40px 40px 40px;
}

#usersnap-btn {
  color: black;
  border: 1px solid black;
  background: none;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.project-goback {
  // margin: 0px 40px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
}

.project-goback:hover {
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .sidebar-container {
    width: 0;
  }

  .page-body-layout {
    // width: 100%;
    // padding: 70px 20px;
  }
}
