$sidebar-bg-color: #161d37;
$sidebar-color: rgba(255, 255, 255, 0.6);
// $icon-size: 20px;
// $sidebar-width: 100%;
$sidebar-collapsed-width: 88px;
// $submenu-indent: 32px;
$highlight-color: white;
$icon-size: 50px;
$breakpoint-sm: 575px;
@import '~react-pro-sidebar/dist/scss/styles.scss';

.pro-sidebar {
  > .pro-sidebar-inner {
    > .pro-sidebar-layout {
      .pro-sidebar-header {
        border-bottom: none;
      }

      .pro-sidebar-content {
        > .pro-menu {
          // padding-top: 6px;
        }
      }

      .pro-sidebar-footer {
        border-top: none;
        > .pro-menu {
          // padding-bottom: 20px;
        }
      }
    }
  }

  .pro-menu {
    .pro-menu-item {
      > .pro-inner-item {
        // padding: 15px 35px 15px 22px;
        @apply sm:py-3;
      }

      > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
        animation: none;
      }
    }
  }
}

// .sm.toggled {
//   min-width: 88px;
//   width: 88px;
//   .pro-menu .pro-menu-item > .pro-inner-item {
//     padding: 15px 12px;
//   }
// }

// .pro-icon {
//   border-radius: 50%;
//   // background: $icon-bg-color;
//   &:hover {
//   }
// }

// .pro-sidebar {
//   height: 100% !important;
//   width: 80px !important;
//   min-width: 80px !important;
//   margin-right: 0 !important;
//   text-align: center !important;
//   transition: width, left, right, 0.3s;
//   position: fixed !important;
//   z-index: 1009;
// }

// .pro-sidebar > .pro-sidebar-inner {
//   background: $dark-color !important;
//   height: 100%;
//   position: relative;
//   z-index: 101;
// }

// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
//   border: none !important;
// }

// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
//   flex-grow: 1;
//   @include flex-style(column, flex-start, center);
//   padding: 10px 0;
// }

// .is-read-mark-sidebar {
//   position: absolute;
//   background-color: red;
//   width: 20px;
//   height: 20px;
//   border-radius: 10px;
//   top: -2px;
//   left: 24px;
//   font-size: 12px;
//   color: white;
//   font-weight: bold;
//   text-align: center;
//   // animation-name: is-read-mark-sidebar-animation;
//   // animation-duration: 4s;
//   // animation-iteration-count: infinite;
// }

// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
//   border: none !important;
//   @include flex-style(row, center, center);
// }

// .pro-sidebar .pro-menu .pro-menu-item {
//   margin-bottom: 30px;
// }

// .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
//   position: relative;
// display: flex;
// align-items: center;
//   @include flex-style(column, center, center);

//   width: 55px;
//   height: 60px;
//   color: #adadad !important;
// }

// .pro-sidebar .pro-menu .pro-menu-item:hover > .pro-inner-item {
//   color: #fff !important;
// }

// .pro-sidebar .pro-menu .pro-menu-item.active > .pro-inner-item {
//   color: #fff !important;
// }

// .flair-logo-container {
//   margin: 20px auto;
//   margin-top: 30px;
// }

// .sidebar-dashboard-icon {
//   width: 28px;
//   height: 28px;
// }

// .pro-sidebar .pro-menu a:hover .sidebar-dashboard-icon {
//   // background: url('../images/dashboard-active.svg') !important;
//   // background-size: cover !important;
// }

// .pro-sidebar .pro-menu a:active .sidebar-dashboard-icon {
//   // background: url('../images/dashboard-inactive.svg') !important;
//   // background-size: cover !important;
// }

// .sidebar-company-icon {
// }

// .sidebar-propertylist-icon {
//   width: 28px;
//   height: 28px;
// }

// .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
//   margin-bottom: 5px !important;
//   margin-right: 2px !important;
// }

// .pro-sidebar .pro-menu a:hover .sidebar-propertylist-icon {
//   // background: url('../images/properties-active.svg') !important;
//   // background-size: cover !important;
// }

// .pro-sidebar .pro-menu a:active .sidebar-propertylist-icon {
//   // background: url('../images/properties-inactive.svg') !important;
//   // background-size: cover !important;
// }

// .sidebar-settings-icon {
//   width: 28px;
//   height: 28px;
// }

// .pro-sidebar .pro-menu a:hover .sidebar-settings-icon {
//   // background: url('../images/settings-active.svg') !important;
//   // background-size: cover !important;
// }

// .pro-sidebar .pro-menu a:active .sidebar-settings-icon {
//   // background: url('../images/settings-inactive.svg') !important;
//   // background-size: cover !important;
// }

// .sidebar-logout-icon {
//   width: 35px;
//   height: 35px;
//   margin: 0 16px 35px 14px;
//   padding: 4.5px 0 0px 0px;
//   background-size: cover;
//   border: none;
//   border-radius: 50%;
//   // background: $icon-bg-color;
// }

// .sidebar-logout-icon:hover {
//   // background: url('../../assets/images/logout-active.svg');
//   background-size: cover;
//   cursor: pointer;
// }

// .sidebar-logout-icon:active {
//   // background: url('../../assets/images/logout-inactive.svg');
//   background-size: cover;
// }

// .sidebar-searchbox-container {
//   display: none;
// }

// .pro-menu-item::marker {
//   display: none !important;
// }

// .pro-sidebar a {
//   text-decoration: none;
//   color: white;
// }

// .pro-sidebar .__react_component_tooltip {
//   opacity: 1 !important;
//   box-shadow: 0 3px 10px rgba(104, 123, 254, 0.15) !important;
// }

// .sidebar-content {
//   font-size: 12px;
// }

// // @keyframes is-read-mark-sidebar-animation {
// //   0%   {background-color:rgba(255, 0, 0, 1);}
// //   25%  {background-color:rgba(255, 0, 0, 0.8);}
// //   50%  {background-color:rgba(255, 0, 0, 1);}
// //   75%  {background-color:rgba(255, 0, 0, 0.8);}
// //   100% {background-color:rgba(255, 0, 0, 1);}
// // }

// @media screen and (max-width: 576px) {
//   .pro-sidebar {
//     height: 70px !important;
//     width: 100vw !important;
//   }

//   .pro-sidebar > .pro-sidebar-inner {
//     height: 72px;
//   }

//   .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
//     height: 72px;
//     overflow-y: auto;
//     overflow-x: hidden;
//     position: sticky;
//     display: flex;
//     flex-direction: row !important;
//     justify-content: space-between;
//     z-index: 101;
//   }

//   .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
//     flex-grow: 0;
//     flex-direction: row;
//     padding: 0;
//     border: none;
//   }
//   .pro-sidebar .pro-menu .pro-menu-item {
//     display: none;
//   }

//   .sidebar-dashboard-icon {
//     display: none;
//   }

//   .sidebar-company-icon {
//     display: none;
//   }

//   .sidebar-propertylist-icon {
//     display: none;
//   }

//   .sidebar-settings-icon {
//     display: none;
//   }

//   .sidebar-logout-icon {
//     display: none;
//   }

//   .sidebar-searchbox-container {
//     display: block;
//     width: calc(100vw - 150px);
//   }

//   .flair-logo-container {
//     margin: 26px 20px;
//   }

//   .search-white-img {
//     position: absolute;
//     top: 15px;
//     left: 10px;
//   }
// }
