.resetpassword-pane {
  width: 50%;
  display: flex;
  justify-content: center;
}

.send-instruct-btn {
  width: 100%;
  height: 52px;
  margin-bottom: 16px;
  margin-top: 32px;
  @include confirm-btn-style;
}

.send-instruct-btn:hover {
  @include confirm-btn-hover-style;
}

.send-instruct-btn:active {
  @include confirm-btn-active-style;
}

.reset-cancel-btn {
  width: 100%;
  height: 52px;
  @include cancel-btn-style;
}

.reset-cancel-btn:hover {
  @include cancel-btn-hover-style;
}

.reset-cancel-btn:active {
  @include cancel-btn-active-style;
}

@media screen and (max-width: 768px) {
  .resetpassword-pane {
    width: 90vw !important;
    margin-left: 5vw;
  }

  .send-instruct-btn {
    margin-top: 40px;
  }
}
